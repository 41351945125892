export const menuItems = [
    {
        id: 2,
        label: "Dashboard",
        icon: "ri-dashboard-line",
        link: '/'
    },
    {
        id: 3,
        label: "Orders",
        icon: "ri-sort-desc",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 6,
                label: 'Active',
                link: '/orders?status=active'
            },
            {
                id: 7,
                label: 'Progress',
                link: '/orders?status=progress'
            },
            {
                id: 9,
                label: 'Completed',
                link: '/orders?status=completed'
            },
        ]
    },
    {
        id: 10,
        label: "Earnings",
        icon: "ri-coins-line",
        link: '/earnings'
    },
    {
        id: 21,
        label: "Notifications",
        icon: "ri-notification-3-line",
        link: '/notifications'
    },
    {
        id: 22,
        label: "Chats",
        icon: "ri-message-2-line",
        link: '/chats'
    },
    {
        id: 25,
        label: "account",
        icon: "ri-settings-2-line",
        isMenuCollapsed: false,
        subItems: [
            {
                id: 26,
                label: 'Profile',
                link: '/account?tab=profile'
            },
            {
                id: 26,
                label: 'Settings',
                link: '/account?tab=settings'
            },
            {
                id: 27,
                label: 'Password',
                link: '/account?tab=password'
            }
        ]
    },
];
